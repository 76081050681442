import React from 'react'
import { Icon } from 'components/utils'

const MyGroups = props => {
  const { title, active, selectedSvg, unSelectedSvg, makeActive, index } = props
  const [firstWord, secondWord] = title.split(' ');

  return (
    <div className="flex flex-1 p-1 lg:w-1/4 md:py-0 md:px-2 xl:px-4 scroll-top-buttons-min-width">
      <button
        data-test={title}
        className={`group-icon rounded-lg ${active ? 'bg-gradient-green-45deg ' : 'bg-white '
          } py-4 px-2 lg:py-6 lg:text-md xl:text-md shadow-md flex justify-center items-center cursor-pointer w-full`}
        onClick={() => makeActive(index)}
      >
        <span className='hidden lg:block md:block'>
          <Icon svg={`${active ? selectedSvg : unSelectedSvg}`} />
        </span>
        <span
          className={`leading-tight group-icon-wrapper ml-1 lg:ml-2 md:ml-4 select-none font-semibold lg:text-lg ${active ? 'text-white' : 'text-green-light'
            }`}
        ><span className="hidden md:block">
            {title}
          </span>
          <span className="block md:hidden">
            {firstWord}
            <br />
            {secondWord}
          </span>
        </span>
      </button>
    </div>
  )
}

export default MyGroups