import React from 'react'
import MyGroups from './MyGroups'
import MyServices from './MyServices'
import SearchContainer from '../search/SearchContainer'
import MyInfoSelected from 'assets/icons/svg/my-info-selected.svg'
import MyInfoUnSelected from 'assets/icons/svg/my-info-unselected.svg'
import MyPaySelected from 'assets/icons/svg/my-pay-selected.svg'
import MyPayUnSelected from 'assets/icons/svg/my-pay-unselected.svg'
import MyWorkSelected from 'assets/icons/svg/my-work-selected.svg'
import MyWorkUnSelected from 'assets/icons/svg/my-work-unselected.svg'
import MyVoiceUnSelected from 'assets/icons/svg/my-voice-unselected.svg'
import MyVoiceSelected from 'assets/icons/svg/my-voice-selected.svg'
import { useSetState } from '../../util'
import { useNewsState } from 'store/news-context'

const Application = ({ groups }) => {
  const restrictedGroups = [
    'ggg_convenience_store_manager_colleagues',
    'ggg_convenience_colleagues',
    'ggg_convenience_child_worker_colleagues',
  ];

  const hasRestrictedGroups = groups.some(group =>
    restrictedGroups.some(restricted => group.includes(restricted))
  );

  const hasChildWorkerGroup = groups.some(group =>
    group.includes('ggg_convenience_child_worker_colleagues')
  );

  const allTabs = [
    {
      title: 'My Work',
      id: 0,
      selectedSvg: MyWorkSelected,
      unSelectedSvg: MyWorkUnSelected,
    },
    {
      title: 'My Info',
      id: 1,
      selectedSvg: MyInfoSelected,
      unSelectedSvg: MyInfoUnSelected,
    },
    {
      title: 'My Voice',
      id: 3,
      selectedSvg: MyVoiceSelected,
      unSelectedSvg: MyVoiceUnSelected,
    },
    {
      title: 'My Benefits',
      id: 2,
      selectedSvg: MyPaySelected,
      unSelectedSvg: MyPayUnSelected,
    },
  ];


  const filteredTabs = allTabs
    .map((tab) => {

      if (hasRestrictedGroups && tab.id === 3) {
        return null;
      }

      if (hasChildWorkerGroup && tab.id === 0) {
        return null;
      }
      return tab;
    })
    .filter(Boolean);


  const defaultActiveIndex = hasChildWorkerGroup
    ? filteredTabs.findIndex(tab => tab.id === 2)
    : hasRestrictedGroups
      ? 0
      : null;
  const defaultState = {
    activeIndex: defaultActiveIndex,
    tabs: filteredTabs,
  };

  const [state, setState] = useSetState(defaultState);
  const newsState = useNewsState();

  const makeTabActive = val => {
    if (state.activeIndex === val) {
      setState({
        activeIndex: null,
      })
    } else {
      setState({
        activeIndex: val,
      })
    }
  }

  console.log('User Groups Passed to Application:', groups);
  console.log('Has Restricted Groups:', hasRestrictedGroups);
  console.log('Has Child Worker Group:', hasChildWorkerGroup);
  console.log('Filtered Tabs:', filteredTabs.map(tab => tab.title));

  return (
    <section className="py-1 bg-grey-transparent">
      <div className="container mx-auto md:px-8">
        <SearchContainer isActive={newsState.isSearchActive} />
        <div className="md:py-4">
          <div className="flex lg:flex-wrap md:-mx-2 lg:-mx-2 xl:-mx-4 scroll-top-buttons">
            {filteredTabs.map((tab) => (
              <MyGroups
                index={tab.id}
                active={tab.id === state.activeIndex}
                key={tab.id}
                title={tab.title}
                selectedSvg={tab.selectedSvg}
                unSelectedSvg={tab.unSelectedSvg}
                makeActive={() => makeTabActive(tab.id)}
              />
            ))}
          </div>
        </div>
        {state.activeIndex !== null && <MyServices activeIndex={state.activeIndex} />}
      </div>
    </section>
  );
};

export default Application;
