import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { TheHeader, HeaderTab } from 'components/header'
import { Applications } from 'components/application/'
import { NewsContainer } from 'components/news'
import TwoSV from 'components/2SV'
import { ErrorBoundary } from 'components/error'
import config from 'config'
import { useUserState } from 'store/user-context'
import { useNewsState } from 'store/news-context'
import { useLocation } from 'react-router-dom'
const LandingPage = () => {
  const userState = useUserState()
  const newsState = useNewsState()
  const { pathname } = useLocation()

  useEffect(
    () => {
      ReactGA.send({ hitType: "pageview", page: pathname, title: "Home Page" })
    },
    [pathname]
  )

  const current_user = userState.current_user
  const { two_sv_colleague } = current_user
  const transitionEffect = newsState.isNewsBarActive ? 'transition-leave' : ''
  console.log('my groups', current_user.groups);
  return (
    <div>
      {config.contentfullApi.host &&
        config.contentfullApi.host === 'preview.contentful.com' && (
          <>
            <marquee
              direction="left"
              speed="normal"
              behavior="loop"
              className="p-2 bg-yellow"
            >
              <span className="text-xl text-green">
                You are currently working in preview mode
              </span>
            </marquee>
          </>
        )}

      {current_user.error ? (
        <div
          id="main_content"
          className="flex flex-col items-center justify-center"
        >
          Error retrieving your details
        </div>
      ) : (
        <div id="main_content" className={`list-page  ${transitionEffect}`}>
          <HeaderTab activeTab="mymorri" />
          <TheHeader />
          <Applications groups={current_user.groups} />
          <ErrorBoundary>
            {!current_user.groups.some(group =>
              ['ggg_convenience_store_manager_colleagues', 'ggg_convenience_colleagues', 'ggg_convenience_child_worker_colleagues', 'convenience.leavers']
                .some(value => group.includes(value))
            ) ? (
              <>
                <NewsContainer />
              </>
            ) : null}
          </ErrorBoundary>
          {two_sv_colleague && <TwoSV />}
        </div>
      )}
    </div>
  )
}

export default LandingPage